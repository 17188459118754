<template>
    <div class="Addport">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="新增模板" :visible.sync="yunyingtan" :closeOnClickModal='false' width="670px" @close="closeDialog()">
                <div class="chuangjian"></div>
                <el-form :model="form">
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            模板名称
                        </div>
                        <el-input v-model="form.message_name" autocomplete="off" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            模板类型
                        </div>
                        <el-select v-model="form.message_channel" placeholder="请选择" clearable>
                            <el-option label="微信消息" value="1" size="120px"> </el-option>
                            <el-option label="站内消息" value="2" size="120px"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            模板内容
                        </div>
                        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.message_temp_json">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            模板说明
                        </div>
                        <el-input type="textarea" :rows="2" placeholder="请输入说明" v-model="form.message_desc">
                        </el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="quxiao" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    props: ["msg"],
    data() {
        return {
            yunyingtan: true, //操控弹窗展示
            form: {
                //表单信息
                message_channel: "", //模板类型
                message_name: "", //模板名称
                message_desc: "", //模板说明
                message_temp_json: "", //模板说明
            },
            formLabelWidth: "140px",
            activeName: "", //显示组件
            imageUrl: "", //头像
            fileListUpload: [], //上传的文件列表
            page: 1,
            inputD: "",
        };
    },
    mounted() {
        // this.getTopCorporateAccountList();
        // if (this.msg == Addport) {
        //     this.activeName == true;
        // }
    },
    methods: {
        // 获取企业信息
        getTopCorporateAccountList() {
            var param = {
                page: this.page,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.inputD = res.data.data;
                        // console.log(this.inputD);
                    }
                }
            );
        },

        quxiao() {
            this.$emit("zeng");
            this.yunyingtan = false;
        },
        closeDialog() {
            this.$emit("zeng");
            this.yunyingtan = false;
        },
        tijiao() {
            var param = this.form;
            // console.log(param);
            // createSysMessageTemp
            this.$service.post(this.$api.createSysMessageTemp, param, (res) => {
                if (res.code == "200") {
                    this.$message({
                        message: "提交成功",
                        type: "success",
                    });
                    this.$emit("bianji");
                    this.yunyingtan = false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.Addport {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
        font-weight: bold;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    //
}
</style>
<style lang="scss">
.Addport {
    .upload-demo {
        width: 200px;
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    // .el-dialog {
    //     width: 35%;
    // }
    .el-input {
        width: 380px !important;
    }
    .el-textarea {
        width: 380px !important;
    }
}
</style>