<template>
    <div class="operation">
        <!--  -->
        <div class="head padd20 borbut20">

            <div class="zhong">
                <el-input v-model="input" placeholder="请输入模板类型"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="sousuo()">搜索</el-button>
            </div>
            <div></div>
        </div>
        <!--  -->
        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">消息模板</div>
                <el-button type="primary" id="AddSystem" @click="addyunying($event)" class="yinyinga">新增模板</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>

            <el-table ref="filterTable" :data="tableData" style="width: 100%">
                <el-table-column prop="id" label="编号" min-width="40%">
                </el-table-column>
                <el-table-column prop="message_code" label="模板CODE" min-width="50%">
                </el-table-column>
                <el-table-column prop="message_channel" label="模板类型" min-width="40%">
                    <template slot-scope="scope">
                        {{scope.row.message_channel == 1 ? '微信消息' : '站内消息'}}
                    </template>
                </el-table-column>
                <el-table-column prop="message_name" label="模板名称" min-width="40%">
                </el-table-column>
                <el-table-column prop="message_temp_json" label="模板内容" min-width="120%">
                    <template slot-scope="scope">
                        <el-tooltip :content="scope.row.message_temp_json" placement="top" popper-class="testtooltip" class="grayg">
                            <span class="sansan">{{scope.row.message_temp_json}}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="创建时间" min-width="70%">
                </el-table-column>
                <el-table-column prop="message_desc" label="模板说明" min-width="120%">
                </el-table-column>
                <el-table-column label="操作" min-width="50%">
                    <template slot-scope="scope">
                        <el-button type="primary" class="bianji" size="mini" id="ComSystem" @click="handleEdit(scope.$index, scope.row,$event)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="fenye">
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
            </div>
        </div>

        <!-- 弹窗 -->
        <div>
            <AddSystem ref="AddSystem" :msg="activeName" @zeng="zeng" @bianji="bianji" v-if="activeName == 'AddSystem'"></AddSystem>
            <ComSystem ref="ComSystem" :id="operating_id" @zeng="zeng" :msg="activeName" @bianji="bianji" v-if="activeName == 'ComSystem'"></ComSystem>
        </div>
    </div>
</template>

<script>
import AddSystem from "./AddSystem.vue";
import ComSystem from "./ComSystem.vue";
export default {
    data() {
        return {
            input: "", //搜索内容
            operating_id: "", //运营官id
            tableData: [], //显示列表
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            page: "1",
            activeName: "",
        };
    },
    mounted() {
        this.getSysMessageTemp();
    },
    components: {
        AddSystem,
        ComSystem,
    },
    methods: {
        //新增模板
        addyunying(e) {
            this.activeName = e.currentTarget.id;
        },
        zeng() {
            this.activeName = "";
        },
        // 获取模板列表
        getSysMessageTemp() {
            var param = {
                page: 1,
            };
            this.$service.get(this.$api.getSysMessageTemp, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                    // console.log(res);
                    this.lvtotal = res.data.total;
                }
            });
        },
        //搜索模板
        sousuo() {
            var param = {
                page: 1,
                message_channel: this.input,
            };
            // console.log(param);
            this.$service.get(this.$api.getSysMessageTemp, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        // 筛选
        filterTag(value, row) {
            return row.tag === value;
        },
        // 编辑
        handleEdit(index, row, e) {
            this.activeName = e.currentTarget.id;
            this.operating_id = row;
            // console.log(index, row);
        },
        // 删除
        handleDelete(index, row) {
            // console.log(index, row);
        },
        bianji() {
            this.getSysMessageTemp();
        },
        // 分页
        DialogCurrentChange(val) {
            // console.log(val);
            this.tableData = [];
            var param = {
                page: val,
            };
            this.$service.get(this.$api.getSysMessageTemp, param, (res) => {
                if (res.code == "200") {
                    // console.log(res.data);
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.operation {
    display: flex;
    flex-direction: column; //垂直显示
    .head {
        display: flex;
        justify-content: center;
    }

    .tab {
        img {
            width: 50px;
            height: 50px;
        }
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .xzyunying {
        margin-bottom: 20px;
    }
    .ii {
        width: 20px;
        height: 20px;
    }
    .iconfont {
        // color: red;
        // margin-top: 24px;
    }
    .red {
        color: red;
    }
    .sansan {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; //需要控制的文本行数
        overflow: hidden;
    }
}
</style>
<style lang="scss">
.operation {
    .el-input {
        width: 180px;
    }
    .el-step__title {
        margin-left: -5px;
    }

    .el-table__column-filter-trigger i {
        color: #0075f7;
        margin-left: 10px;
    }
    .el-form-item__label {
        text-align: left;
    }
    .el-form-item {
        margin-top: 20px;
    }
    .el-form-item__content {
        margin-left: 0 !important;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
    .el-dialog__header {
        padding: 20px 20px;
    }
}
.testtooltip {
    width: 500px;
}
</style>